<template>
    
<div class="share-container" >
  <div class="share-bg"></div>
  <div class="share-main">
    <img class="share-img" src="@/assets/images/share.png" alt="" />
  </div>
</div>
</template>
<style>

.share-container {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index:999;
}
.share-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #232327;
  opacity: 0.5;
}
.share-main {
  position: relative;
  z-index: 100;
  height: 100%;
}
.share-img {
  height: auto;
  width: 100%;
}
</style>